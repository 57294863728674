{
    "accredited_links": {
        "actions": {
            "revoke": "Revoke"
        },
        "defaults": {
            "created_at": "N/A",
            "created_by": "N/A",
            "name": "Generic permalink"
        },
        "list": {
            "created_at": "Generated at",
            "created_by": "Generated by",
            "revoked_at": "Revoked {0}",
            "title": "Title"
        },
        "messages": {
            "links_revoke_error": "Could not revoke the link|Could not revoke selected links",
            "links_revoked": "Link has been revoked successfully|Links have been revoked successfully",
            "revoke_prompt": "Are you sure you want to revoke this link? This action can't be undone.|Are you sure you want to revoke {0} selected links? This action can't be undone."
        }
    },
    "asset_library": {
        "details": {
            "file_name": "File name",
            "file_size": "File size",
            "original_content_type": "Content type",
            "timestamp": "Uploaded at"
        },
        "list": {
            "actions": {
                "delete": "Delete"
            },
            "headers": {
                "file_size": "Size",
                "preview": "Preview",
                "title": "File name",
                "type": "Type",
                "upload_date": "Uploaded at"
            },
            "messages": {
                "prompt_deletes": "Are you sure you want to delete the asset?|Are you sure you want to delete {n} assets?"
            }
        }
    },
    "base": {
        "and": "and",
        "by": "by",
        "no": "No",
        "none": "None",
        "not_available": "N/A",
        "ok": "Ok",
        "or": "or",
        "unknown": "<unknown>",
        "yes": "Yes"
    },
    "blueprint": {
        "modules": {
            "accredited_links": "Permalinks",
            "apiDoc": "API Documentation",
            "apiTokens": "API tokens",
            "apps": "Apps",
            "assets_library": "Assets library",
            "change_password": "Change password",
            "content_hubs": "Content hubs",
            "edit_template": "General",
            "edit_template_design": "Design",
            "edit_template_integrations": "Integrations",
            "edit_template_post_event_form": "Post event form",
            "edit_template_registrations": "Registrations page",
            "eventSettings": "General",
            "eventTeam": "Team",
            "events": "Create new workspace",
            "features": "Features",
            "home_editor": "Home editor",
            "marketplace": "Marketplace",
            "metadata": "Metadata",
            "organisations": "Organizations",
            "registration": "Registration",
            "templates": "Templates",
            "themeEditor": "Theme editor",
            "userManagement": "User accounts",
            "users": "Profile",
            "video_library": "Video Library",
            "webinars": "Webinars",
            "welcome": "Workspaces",
            "workspaces": "Create new workspace"
        },
        "submenus": {
            "compliance_footer": "Footer",
            "compliance_header": "Header",
            "home_feed": "Home feed",
            "home_launcher": "Home launcher"
        }
    },
    "checklist": {
        "progress": "Progress",
        "task_completed": "Task completed",
        "title": "Getting started"
    },
    "clauses": {
        "terms_of_service": "I accept SpotMe's <strong><a href='{0}' target='_blank'>Terms of Service</a></strong>."
    },
    "content_hubs": {
        "all": "All",
        "content_hubs": "Content Hubs",
        "counters": {
            "selected": "selected",
            "webinars": "Webinar|Webinars",
            "workspaces": "Workspace|Workspaces"
        },
        "create": "New content hub",
        "created": "Created {0}",
        "creation": {
            "action": "Create content hub",
            "creation_title": "Creating content hub",
            "errors": {
                "failure": "Could not create content hub.",
                "job_registration": "Unable to register creation job."
            },
            "main_title": "New content hub"
        },
        "empty": "Share recordings of sessions, on-demand videos, documents and much more in a branded and customizable way!",
        "form": {
            "accordion": {
                "message": "Connect events in order to automatically make live stream recordings available on the content hub, or upload videos and documents directly from your computer!",
                "show_less": "Less info",
                "show_more": "More info",
                "title": "Share on-demand videos, documents and much more!"
            },
            "container_app": "Default app to host this content hub",
            "container_app_hint": "This content hub will be visible only in this app",
            "container_app_placeholder": "Select a container app",
            "fieldsets": {
                "app": "Container app",
                "details": "Content hub details",
                "privacy": "Privacy"
            },
            "legal_documents": "Privacy policies",
            "legal_documents_placeholder": "Choose policies",
            "name": "Content hub name",
            "organization": "Create for",
            "organization_placeholder": "Select an organization",
            "privacy_intro": "Users will be able to view the documents selected here when accessing a content hub. At least one Privacy Policy must be selected to create a content hub.",
            "servers_location": "Data location",
            "servers_location_placeholder": "Select a server location"
        },
        "latest": "Last accessed",
        "welcome": "Welcome!"
    },
    "contents": {
        "closed_captions": {
            "add": "Add closed captions +",
            "add_language": "Add languages",
            "captions_already_in_video": "The video file already includes embedded closed captions, which cannot be modified or replaced.",
            "delete": "Delete",
            "edit": "Edit closed captions",
            "edit_language": "Edit languages",
            "hint": "File types: VTT. Max file size 1MB.",
            "title": "Closed captions",
            "upload_error": "We had trouble processing your file. Please make sure it’s a valid VTT file and try again"
        },
        "delete": {
            "body": "Are you sure you want to delete the {0}?{1}If published, it will also be deleted from the app",
            "title": "Delete {0}"
        },
        "detail": {
            "actions": {
                "discard": "Cancel",
                "save": "Save"
            },
            "alt_text": "Alternative text",
            "back": "All videos",
            "date": "Date",
            "description": "Description",
            "document_title": "Document title",
            "error": "Error...",
            "event_name": "Connected event",
            "failed": "Upload failed",
            "file_size": "File size",
            "preview_label": "Preview not available",
            "processing": "Processing...",
            "status": "Status",
            "timestamp": "Stream date",
            "title": "Video title",
            "type": "File type"
        },
        "messages": {
            "contents_loading_failed": "Could not load contents",
            "delete_failed": "Could not delete selected item, try again later or contact support.",
            "delete_success": "Successfully deleted.",
            "downloaded": "Successfully downloaded",
            "email_pax_error": "Could not check the number of particpants, try again later or contact support.",
            "email_scheduled": "{0} email scheduled successfully|{0} emails scheduled successfully",
            "email_scheduled_to_users": "Email scheduled successfully for {0} user|Email scheduled successfully for {0} users",
            "email_send_error": "Could not send email, try again later or contact support.",
            "email_sent": "{0} email sent successfully|{0} emails sent successfully",
            "email_sent_to_users": "Email sent successfully to {0} user|Email sent successfully to {0} users",
            "export_failed": "Could not complete the export. Please try again later or contact the support.",
            "thumbnail_deletion_error": "Thumbnail deletion failed",
            "thumbnail_deletion_success": "Thumbnail deleted successfuly",
            "thumbnail_upload_error": "Thumbnail upload failed",
            "thumbnail_upload_success": "Thumbnail uploaded successfuly",
            "title_saved": "The title has been successfully changed",
            "title_saved_error": "Updating the title failed",
            "updated": "Successfully updated",
            "updated_error": "Update failed"
        },
        "thumbnail": {
            "add_from_list": "THUMBNAIL",
            "hint": "File types: PNG, JPEG. Max file size 1MB. Recommended resolution 1280x720 px.",
            "label": "Thumbnail",
            "tip": "This thumbnail will replace the default thumbnail."
        },
        "type": {
            "content-document": "document | documents",
            "content-video": "video | videos"
        }
    },
    "dashboard": {
        "countdowns": {
            "archived_in": "It will be archived in {0} days",
            "ends_in": "Your event will finish in {0}",
            "finished_on": "Your event finished on {0}",
            "starts_in": "Your event starts in {0}",
            "will_soon_be_archived": "This workspace will soon be archived"
        },
        "navigate_to_legacy_dashboard": "Go to legacy event dashboard"
    },
    "dev_tools": {
        "document_editor": {
            "add_new_doc": "New document",
            "attachments": {
                "delete": "Delete attachment",
                "delete_prompt": "Are you sure you want to delete {0}?",
                "title": "Attachments ({0})"
            },
            "diff": {
                "compare": "Compare to selected",
                "restore": "Restore"
            },
            "document_saved": "Saved",
            "info": "Document info",
            "linked_docs": "Linked documents",
            "messages": {
                "could_not_load_doc": "Could not load the document: {0}",
                "could_not_save_doc": "Could not save the document: {0}",
                "doc_deleted": "This document was deleted.",
                "doc_not_found": "Could not find the requested document",
                "document_url_copied": "Document URL copied to your clipboard",
                "document_url_not_copied": "Could not copy document URL to your clipboard"
            },
            "new_doc": "New document",
            "restore_revision": "Restore revision",
            "revision_not_restored": "Could not restore revision.",
            "revision_restore": "Are you sure you want to roll back this document to revision: {0}?",
            "revision_restored": "Revision successfully restored.",
            "revisions": "Revisions",
            "share": "Share",
            "timestamp": "Timestamp"
        },
        "pkg_inspector": {
            "contexts": {
                "appscript": "AppScript",
                "backstage": "Backstage definition",
                "frontstage": "Fronstage definition",
                "handler": "Node handler"
            },
            "modified": "{0} file|{0} files",
            "operations": {
                "deploy": "Deploy",
                "eventCreation": "Event creation",
                "install": "Install",
                "update": "Update"
            }
        }
    },
    "dynamic_fields": {
        "add_description": "Description",
        "add_field": "Field",
        "add_session_registration": "Session registration",
        "add_session_registration_capacity_enabled": "Disable registration capacity in the registration settings page to enable session registration.",
        "add_session_registration_manual_approval_enabled": "Disable manual approval in the registration settings page to enable session registration.",
        "editable_tip": "Selected fields will be editable by attendees when updating their profile information",
        "editable_title": "Editable",
        "fields_title": "Field",
        "number_of_sessions": "{0} session|{0} sessions",
        "required_title": "Mandatory",
        "sessions_registration": "Sessions registration"
    },
    "errors": {
        "bad_request": {
            "limit_contact_support": "More information on this and the actions you can take are available {0}.",
            "limit_exceeded": "You have reached the maximum number of {0}s ({1}).",
            "limit_link_label": "here"
        },
        "server": {
            "403": "Action forbidden.",
            "404": "Resource not found.",
            "405": "Method not allowed.",
            "502": "Service is temporarily out of order.",
            "generic": "Service is temporarily out of order."
        }
    },
    "event_badges": {
        "current_stage": {
            "future": "Future",
            "live": "Live",
            "past": "Past"
        },
        "visibility": {
            "global": "global",
            "private": "private",
            "public": "public",
            "unknown": "unknown"
        }
    },
    "events": {
        "all": "All",
        "archived": {
            "prompt": "This workspace has not been active for a while and has been archived. Do you want to restore it?",
            "restore": "Restore",
            "restore_error": "Unable to complete the workspace restoration due to a server error.",
            "restore_in_progress_message": "This workspace is currently being restored. Please try again in a few minutes.",
            "restore_in_progress_title": "Restore already in progress",
            "restore_not_archived": "The workspace you are trying to restore is not archived.",
            "restore_success": "Your restore request has been successfully submitted. Your workspace shall be available shortly. You will receive a confirmation email when it is ready.",
            "restore_title": "Restoring workspace"
        },
        "create": "New workspace",
        "empty": "We couldn't find anything but potential here, get started by creating your first workspace now!",
        "errors": {
            "deleted": "The event you tried to access was deleted.",
            "unathorized_or_not_found": "The event does not exist or you do not have the permissions to access it."
        },
        "future": "Future",
        "getting_started": "Get started",
        "latest": "Last accessed",
        "live": "Live",
        "past": "Past",
        "stages": {
            "future": "Starting {0}",
            "live": "Started {0}",
            "past": "Ended {0}"
        },
        "welcome": "Welcome!",
        "workspaces": "Workspaces"
    },
    "exports": {
        "badge_qr_image": {
            "action": "Export user QR code images",
            "description": "Download QR images for all users in the workspace.",
            "title": "Export user QR code images"
        },
        "qr_info": {
            "action": "Export user QR information",
            "description": "Download an excel file which contains individual user information required to generate QR codes with a 3rd party provider.",
            "title": "Export user QR information"
        },
        "shortlink_qr_image": {
            "action": "Export shortlink QR code images",
            "description": "Download QR images for all shortlinks.",
            "title": "Export shortlink QR code images"
        },
        "shortlink_reports": {
            "action": "Export QR shortlink scans",
            "description": "Download an excel file that contains information related to the QR code shortlink scans done by participants.",
            "title": "Export QR shortlink scans"
        }
    },
    "field_name": {
        "city": "city",
        "container_app": "container app",
        "end_date": "ends at",
        "name": "name",
        "newPass": "new password",
        "organization": "organization",
        "privacy_documents": "privacy documents",
        "servers_location": "server location",
        "start_date": "starts at",
        "timezone": "Time zone"
    },
    "file_upload": {
        "default": "Drag and drop or upload from computer",
        "drag_drop": "Drag and drop or",
        "errors": {
            "general_error": "Something went wrong when uploading the file. Please try again later.",
            "infected_file": "The uploaded file has a virus and will not be accessible.",
            "transcoding_failed": "Something went wrong when converting the file. Please make sure the video file has correct format."
        },
        "exceeded_size": "File too big. Max allowed size: {0}",
        "invalid_file": "Please choose a valid file: {0}",
        "start": "Start upload",
        "upload": "upload from computer"
    },
    "fstg": {
        "create_page": {
            "prompt": "Choose new page name"
        }
    },
    "general": {
        "accept": "Accept",
        "add": "Add",
        "app": "Backstage",
        "apply": "Apply",
        "auto_reload_in": "The page will automatically reload in {0}.",
        "cancel": "Cancel",
        "close": "Close",
        "confirm": "Confirm",
        "confirm_prompt": "Please confirm",
        "conflicting_packages": "You should consider updating the following packages first:",
        "continue": "Continue",
        "copy": "Copy",
        "copy_error": "Could not copy to clipboard, please do it manually: {0}",
        "copy_failed": "Copy to clipboard failed",
        "copy_of": "Copy of",
        "copy_success": "Copied to clipboard",
        "could_not_download": "Could not automatically download the file. Please proceed manually.",
        "create": "Create",
        "custom": "Custom",
        "delete": "Delete",
        "deleted": "Deleted",
        "deletion_confirmation": "This action can't be undone. Are you sure you want to proceed?",
        "description": "Description",
        "disabled": "Disabled",
        "discard": "Discard",
        "dismiss": "Dismiss",
        "done": "Done",
        "download": "Download",
        "download_png": "Download .png",
        "edit": "Edit",
        "empty": "No results were found for {0}",
        "enabled": "Enabled",
        "error_details": "Error details",
        "error_occurred": "An error occurred",
        "export": {
            "progress": "generating export..."
        },
        "false": "False",
        "file_name": "File name",
        "file_size": "File size",
        "file_type": "File type",
        "forms": {
            "add_new": "Add new item",
            "common": "Common",
            "content_page": {
                "create_page": "Create a new frontstage page",
                "name": "page name",
                "name_hint": "Choose the name for new frontstage page",
                "page_not_saved": "Please save the change before editing the page.",
                "types": {
                    "content-page": "Content pages",
                    "form": "Forms",
                    "lead": "Leads",
                    "map": "Maps",
                    "other": "Others",
                    "person": "People",
                    "questionnaire": "Questionnaire",
                    "session": "Sessions"
                }
            },
            "deselect_all": "Deselect all",
            "dirty_form": "There are unsaved changes that will be lost if you close. Are you sure you want to close the form?",
            "drag_and_drop": "Drag and drop or {0}",
            "drag_and_drop_action": "upload from computer",
            "fp_ext_id_hint": "The unique identification code is the fp_ext_id",
            "fp_ext_id_title": "Unique identification code",
            "html_editor": {
                "message": {
                    "asset_upload_failed": "Could not upload the asset. Please try again later or contact the support."
                }
            },
            "invalid_form": "Your form is invalid. Please fill the required fields.",
            "kinds": {
                "hidden": "Hidden"
            },
            "labels": {
                "address": "Address",
                "biography": "Biography",
                "city": "City",
                "company": "Company",
                "country": "Country",
                "email": "Email address",
                "fname": "First name",
                "linkedin": "LinkedIn URL",
                "lname": "Last name",
                "phone": "Phone number",
                "position": "Job title (position)",
                "region_state": "Region/State",
                "status": "Status",
                "website": "Website",
                "zip": "ZIP code"
            },
            "max_selection_reached": "Maximum of {0} options selected. First remove a selected option to select another.",
            "no_valid_files": "No valid files detected, please choose only the allowed types and of the proper size.",
            "others": "Others",
            "select_a_reference": "Select a {0}",
            "select_all": "Select all",
            "unsaved_changes": {
                "line_1": "There are unsaved changes that will be lost if you leave the page.",
                "line_2": "Are you sure you want to leave the page?",
                "title": "Unsaved changes"
            }
        },
        "helps": {
            "date_formats": {
                "am_pm": "AM/PM",
                "date_formatting": "Date formatting",
                "day_of_month": "Day of Month",
                "day_of_week": "Day of Week",
                "fractional_second": "Fractional Second",
                "hour": "Hour",
                "intro": "The following tokens are available for date formatting. Please note that the tokens are case sensitive.",
                "minute": "Minute",
                "modal_title": "Date formatting",
                "month": "Month",
                "outcome": "Outcome",
                "second": "Second",
                "time_formatting": "Time formatting",
                "time_zone": "Time Zone",
                "token": "Token",
                "unit": "Unit",
                "unix_millisecond_timestamp": "Unix Millisecond Timestamp",
                "unix_timestamp": "Unix Timestamp",
                "week_of_year": "Week of Year",
                "year": "Year"
            }
        },
        "leave": "Leave",
        "link_not_secure": "This link is not secure. Only https links are allowed.",
        "loading": "loading data...",
        "long_operation_warning": "This might take a while...",
        "new": "New",
        "no_description": "No description",
        "no_elements": "No elements found",
        "not_available": "N/A",
        "off": "Inactive",
        "on": "Active",
        "open_in_webapp": "Preview in Web App",
        "paste_failed": "Paste from clipboard failed",
        "paste_success": "Pasted from clipboard",
        "platforms": {
            "android": "Android",
            "ios": "iOS",
            "web": "Web app"
        },
        "proceed": "Proceed",
        "reject": "Reject",
        "reload": "Reload",
        "remove": "Remove",
        "rename": "Rename",
        "report_issue": "Report an issue",
        "save": "Save",
        "save_changes": "Save changes",
        "search": "Search...",
        "search_email": "Search by email...",
        "service": {
            "access_denied": "Sorry, it seems you don’t have access to view this page.",
            "not_found": "Oops! This page doesn’t exist."
        },
        "settings": "Settings",
        "show_less": "Show less",
        "show_more": "Show more",
        "signal_error": "We're having an issue establishing a stable connection to our servers.",
        "signal_error_please_reload": "Try reloading the page in a few moments. If this issue persists, please make sure your browser is up to date and try to disable your VPN, or firewall.",
        "signal_warning": "We're establishing a stable secured connection to our servers.",
        "signal_warning_please_wait": "This is taking longer than expected.",
        "signal_wss_blocked": "If the problem persists, please contact your network administrator to ensure that your domain and corporate network are not blocking WSS connections. Please refer your network administrator to ",
        "signal_wss_blocked_href": "https://support.spotme.com/hc/en-us/articles/360051642033-Domain-allow-list-and-split-tunneling-on-corporate-networks",
        "signal_wss_blocked_link": "this article",
        "something_wrong": "Something went wrong",
        "true": "True",
        "try_later": "Please try again later. If the problem persists, contact the support.",
        "upload": "Upload",
        "uploads": {
            "failed": "Upload failed",
            "processing": "Processing..."
        },
        "warning": "Warning"
    },
    "general_settings": {
        "actions": {
            "archive": "Archive",
            "convert_to_template": "Convert to template",
            "create_template": "Create template",
            "move_to_preprod": "Move to preproduction"
        },
        "advanced": {
            "title": "Advanced"
        },
        "app_access": {
            "title": "App access"
        },
        "apps_prefs": {
            "date": "Dates and times",
            "home_launcher": "Home launcher",
            "notifications": "Notifications"
        },
        "form": {
            "act_codes": "Event passwords",
            "app_direct_login": "Enable app activation from magic links",
            "attended_event": "Attended event if",
            "attended_session": "Attended session if",
            "calendar_description": "Description",
            "calendar_location": "Location",
            "calendar_title": "Title",
            "category": "Category",
            "city": "Location",
            "close_access_native": "Close mobile app access",
            "close_access_native_hint": "Attendees will not be able to access the event through the mobile app.",
            "close_access_webapp": "Close web app access",
            "close_access_webapp_hint": "Attendees will not be able to access the event through the web app.",
            "container_app": "Container app",
            "container_app_hint": "This workspace will be visible only in this app",
            "container_app_placeholder": "Select a container app",
            "cookies_banner": "Webapp cookie banner",
            "count_vod": "Consider Video On Demand (VOD)",
            "country": "Country",
            "country_placeholder": "Select country",
            "country_tip": "Select the country where the event will be hosted. You can select more than one if relevant.",
            "enable_google_calendar": "Enable Google calendar invitations",
            "enddate": "Ends at",
            "entitlements": "Entitlements",
            "entitlements_v2": "Auto entitlements",
            "event_activated_options_in_person": {
                "app_activated": "Checked into the event or activated the app",
                "sessions_count": "Checked into the event or attended at least X sessions"
            },
            "event_activated_options_virtual": {
                "app_activated": "Activated the app",
                "sessions_count": "Attended at least X sessions"
            },
            "event_type": "Event type",
            "eventname": "Workspace name",
            "hide_cancelled": "Hide cancelled users from listing in backstage",
            "hours": "hours",
            "in_app_subtitle": "Subtitle",
            "in_app_title": "Title",
            "legal_documents": "Policies",
            "legal_documents_placeholder": "Pick the documents",
            "max_duration_hours": "Max stream duration",
            "max_preview_hours": "Max preview duration",
            "max_rtmps_duration_hours": "Max stream duration",
            "max_rtmps_preview_hours": "Max preview duration",
            "metadata_fields_limit": "Metadata fields number limit",
            "metadata_fields_limit_increase_ticket": "Support ticket number for limit increase",
            "min_sessions_to_attend": "Minimal sessions to attend",
            "one_time_links": "Webapp one-time links",
            "organization": "Organization",
            "parent_event": "Container workspace",
            "parent_event_tip": "Use only for workspaces that are part of a Membership app. Selecting a container workspace will make this workspace invisible from the invitation and workspace list in-app.",
            "participated_activity": "Participated in interactivity (polling/Q&A)",
            "redirect_url": "Redirect URL when closed",
            "scanned": "Scanned for attendance",
            "session_ttl": "Session TTL seconds",
            "stage": "Production status",
            "stage_test": "Test workspace",
            "stage_test_tip": "Test workspace: Users and paid modules are not charged. Can only email one user at a time. Event is not visible in the app container or the attendee’s list of events.",
            "stage_tip": "Select \"Production Workspace\" when you are ready to send the invitation email or when your workspace is about to go live. This will make sure the workspace is visible on user's devices when using email activation.",
            "stages": {
                "preprod": "Preproduction workspace",
                "prod": "Production workspace"
            },
            "startdate": "Starts",
            "timezone": "Venue timezone",
            "timezone_placeholder": "Pick a timezone",
            "timezone_tip": "Changing timezone will require you to manually check all time-related data, such as agenda items, scheduled messages, etc.",
            "watch_time": {
                "min_watch_time": "Min watch time",
                "percentage": "Watch time in %",
                "total": "Watch time total (minutes)",
                "watch_time_percentage": "Watch time %"
            },
            "white_list_branding": "Hide from other apps",
            "white_list_branding_positive": "Shown in other apps",
            "whitelisted_ips": "Whitelisted IPs"
        },
        "info": {
            "allow_non_unique_email": "Shared email enabled",
            "creation": "Creation",
            "is_anonymized": "Anonymised",
            "mail_provider": "Email provider",
            "white_label_domain": "Custom domain"
        },
        "menu": {
            "activation": "Activation",
            "attendance_criteria": "Attendance criteria",
            "attendance_criteria_accordeon": {
                "default": "Attendance criteria"
            },
            "date": "Apps timezone",
            "labelling": "Label customization",
            "legal_options": "Legal",
            "logistics": "Logistics",
            "notifications": "Notifications",
            "support_options": "Admin & Support",
            "webapp_settings": "Web app settings",
            "workspace_info": "Workspace information"
        },
        "messages": {
            "app_not_found": "You either do not have access to this app, or it no longer exists. This may also occur if the app's organization is different from the workspace's organization.",
            "archive": "Archive this workspace",
            "archive_confirmation": "Are you sure you want to archive this workspace?",
            "archive_confirmation_title": "Please confirm",
            "archiving_in_progress": "Workspace will be archived",
            "archiving_request_failed": "Could not archive the event. Check the logs and try again. If the problem persists contact engineering.",
            "conversion_failure": "Could not convert to preproduction. Try again later, if the problem persists contact the support.",
            "conversion_success": "Converted to Preproduction",
            "convert_to_template": "Convert this workspace to a Template",
            "create_template": "Create a Template from this workspace.",
            "home_launcher_line_1": "Choose what to display on your event app's home page. ",
            "home_launcher_line_2": "By default, it shows the Home Feed where you can display aggregated posts, a banner, pinned post, and quick links. This can be customized via the Home feed page. ",
            "home_launcher_line_3": "Alternatively, you can use the dropdown to select any specific page, such as a session, form, or other content, as the home page instead.",
            "in_person_attendance": "Attendance for in-person events can be recorded by: event-level check-in (and optionally badge printing), session attendance (session check-in), or app activation. Please keep in mind that event-level check-in and session check-in are SpotMe’s paid modules. If they are not part of your SpotMe plan but you would like to use them to track attendance during your in-person event, please reach out to your SpotMe Account Manager.",
            "legal_docs_intro": "Participants will have to accept requirements specified in documents below before accessing the workspace.",
            "metadata_fields_limit_hint": "Number of fields that a metadata type can declare",
            "metadata_fields_limit_increase_ticket_hint": "Increase of the limit requires a support ticket to be provided",
            "no_archive_permission": "You don't have enough clearance for archiving this event.",
            "one_time_links_tip": "When sending a web app link from Backstage, the link can only be clicked once. If you attempt to click it a second time, it will no longer work and will redirect you to the container's login page. This does not apply to links sent from the registration page.",
            "org_changed": "After changing the event to a new organization, make sure the event type is still valid in the logistic menu item",
            "parent_event_message": "Setting a container workspace will hide this workspace in the invitation and workspace list in-app.",
            "parent_event_set": "Container workspace",
            "preprod_confirmation": "Are you sure you want to move this test workspace to preproduction? You will be charged for the users and installed paid modules. Once the workspace is in preproduction you can then move it to production.",
            "reload_confirm": "Backstage will now reload to apply the changes.",
            "save_failed": "Could not save configuration. Try again later, if the problem persists, please contact the support.",
            "save_success": "The configuration has been saved successfully.",
            "session_ttl_hint": "The user will be logged out of the web app after X seconds and redirected to the container's login page.",
            "settings_save_failure": "An error occurred while saving the settings, try again later or contact the support",
            "settings_save_success": "The settings have been successfully saved.",
            "template_conversion_confirmation": "Are you sure you want to convert this workspace to a template?",
            "tz_change": "Changing timezone will require you to manually review all time-related data, such as agenda items and scheduled messages.",
            "tz_change_title": "Timezone change",
            "unusable_container_message": "This container app is set to hide non linked workspaces, it can only be chosen if \"Hide from other apps\" option is selected.",
            "unusable_container_title": "This container app cannot be used",
            "whitelisted_ips": "IP address e.g. 255.255.255.0/32",
            "whitelisted_ips_hint": "Only users from the specified IP range can access the web app.",
            "wrong_container_message": "This workspace is associated with a container set to hide non linked workspaces. Until \"Hide from other apps\" option is selected, it will not be visible in the container app.",
            "wrong_container_title": "This workspace might have the wrong container app"
        },
        "section_titles": {
            "admin": "Admin options",
            "calendar": "Calendar invite",
            "congress_excellence": "Congress excellence",
            "event_config": "Event's configuration",
            "event_specs": "Event specifications",
            "in_app_display": "In app display",
            "in_person_attendees": "In-person attendees",
            "languages": "Languages",
            "legal": "Legal",
            "live_streams": "Live streams",
            "location": "Location & timezone",
            "rtmps": "RTMPS",
            "studio": "Studio",
            "support": "Support options",
            "virtual_attendees": "Virtual attendees",
            "webapp": "Web app settings",
            "webapp_settings": "Web app settings",
            "workspace_data": "Workspace data"
        },
        "templating": {
            "based_on": "This workspace is based on template:",
            "title": "Templating"
        },
        "titles": {
            "event_settings": "General settings"
        },
        "users": {
            "non_unique_emails_allowed": "User creation with shared email addresses allowed.",
            "title": "Users"
        }
    },
    "generic_form": {
        "contextual": {
            "list": {
                "add": "Add new item",
                "edit": "Edit item",
                "item": "List item",
                "remove": "Remove item"
            },
            "nested": {
                "title": "Editing {0}"
            }
        },
        "custom_actions": {
            "gerenric_button": "Custom action"
        },
        "external_picker": {
            "external_participant": "External participant",
            "new_external": "New {0}",
            "options_selected": "{0} item selected|{0} items selected"
        },
        "messages": {
            "created": "Item saved successfully",
            "save_failed": "Could not save item. Please try again later or contact the support."
        },
        "types": {
            "auto": "Autogenerated",
            "meta_edit": "Manage metadata fields",
            "nested": "Edit {0}",
            "timestamp": "Times are in event's timezone"
        },
        "video": {
            "cancel": "Cancel video call",
            "captions_disabled": "Disable captions",
            "captions_enabled": "Enable captions",
            "embed": "Enable embedded frame",
            "embed_tip": "Embedding a frame will disable screenshare.",
            "join": "Join video call",
            "schedule": "Schedule video call",
            "url": "Embed URL",
            "video_call_moderators": "Moderators"
        }
    },
    "leads": {
        "capture_methods": "Capture methods",
        "presentation": "Default lead qualification page",
        "settings": {
            "capture_methods": {
                "allow_manual_input": "Manual entry",
                "allow_manual_input_hint": "To capture leads, the necessary metadata is defined in Metadata Manager > Prospects",
                "allow_ocr_scanner": "Optical scan",
                "allow_ocr_scanner_hint": "To capture leads, the necessary metadata is defined in Metadata Manager > Prospects",
                "allow_qr_scanner": "QR code scan",
                "allow_qr_scanner_hint": "Capture leads based on existing SpotMe users list.",
                "intro": "Enable at least one option for representatives to use in capturing leads."
            },
            "error_loading": "Could not load current settings. Please try again later or contact the support.",
            "intro": "To grant specific users the ability to capture leads, you must add them as representatives on the sponsor page.",
            "qualification_page": {
                "intro_1": "By default, this form is used by all sponsors with representatives scanning leads at the event.",
                "intro_2": "Specific lead qualification page can be connected to specific sponsors. To do this, access the sponsor page, disconnect the default page, and connect the new page."
            }
        }
    },
    "legal_documents": {
        "add_new_doc": "Add new document",
        "app": {
            "legal_pdf_documents": {
                "add_language": "Add language",
                "add_new_language": "Add a new language",
                "alert_1": "Ensure the document size remains reasonable, ideally below 1 MB.",
                "alert_2": "Documents will be available offline.",
                "info": "Begin by adding a language to the specific platform. Once the language has been added, you can add the document to that specific language.",
                "platform": {
                    "android": "Android platform",
                    "ios": "iOS platform",
                    "other": "Other platforms"
                },
                "title": "Legal PDF Documents",
                "upload_document": "Upload document",
                "upload_instructions": "After the upload, the file will be converted into a link, allowing you to display the document."
            },
            "legal_requirements": {
                "create_new_document": "Create new document",
                "info": "Users are required to accept all requirements from linked documents before they can activate the app.",
                "title": "Legal requirements"
            },
            "show_cookie_banner": {
                "label": "Show cookies banner",
                "title": "Webapp cookies banner"
            },
            "show_foss": {
                "label": "Show Free and Open Source Software licences (Attributions)",
                "title": "Open Source Softwares"
            }
        },
        "editor": {
            "asset_upload_failed": "Could not upload the asset. Please try again later or contact the support.",
            "create_new": "Create new document",
            "doc_saved": "Successfully saved legal document",
            "doc_type": {
                "link": "Existing document",
                "text": "New document"
            },
            "doc_type_explanation": {
                "link": "Paste an existing link or upload a file. If you upload a file, it will be converted into a link after the upload, allowing you to display the document.",
                "text": "Use the editor below to create your document. It will be displayed as a web page with basic formatting."
            },
            "edit": "Edit",
            "requirements": "Requirements",
            "template": "Template",
            "template_disclaimer": "I understand this template is not intended as a substitute for legal advice as indicated in detail in the Backstage Terms of Services",
            "template_or_not": {
                "create_doc": "Create document from scratch",
                "use_template": "Start from a template"
            },
            "text": "Text",
            "title": "Title",
            "upload": "Upload a file",
            "url": "Link"
        },
        "table": {
            "last_changed": "Last changed",
            "preview": "Preview",
            "title": "Title"
        }
    },
    "legal_picker": {
        "actions": {
            "add_legal": "Legal document",
            "create": "Create"
        },
        "creation": {
            "form": {
                "link": "Link",
                "link_tip": "Link to the legal document, alternatively provide a text below",
                "template": "Template",
                "template_placeholder": "Choose a template",
                "text": "Text",
                "title": "Title"
            },
            "title": "Create a new legal document"
        },
        "messages": {
            "not_loaded": "Could not load legal documents."
        }
    },
    "listings": {
        "actions": {
            "attaching_xls": "Uploading xlsx file. This may take a few minutes.",
            "bulk_edit": "Bulk edit",
            "bulk_update": "Modify {0} record|Modify {0} records",
            "cancel": "Cancel",
            "copy_permalink": "Copy permalink",
            "deactivate": "Deactivate",
            "delete": "Delete",
            "delete_user": "Deactivate and cancel",
            "download_export": "Download XLS",
            "duplicate": "Duplicate",
            "edit_users": "Modify {0} user|Modify {0} users",
            "export_xls": "Export XLS",
            "generate_xls": "Generate XLS",
            "generating": "Generating;",
            "generation_done": "Generated {0}",
            "generation_progress": "Progress: {0}%",
            "generation_started": "started: {0}",
            "import_xls": "Import XLS",
            "mark_user_active": "Show in app",
            "mark_user_hidden": "Hide in app",
            "meetings_deletion": "Delete meeting|Delete meetings",
            "meetings_deletion_alert": "Deleted items will be permanently removed and won't be visible in your reports anymore.",
            "meetings_deletion_and_notify": "Delete and notify",
            "meetings_deletion_without_notify": "Delete without notifying",
            "new_meeting": "New meeting",
            "new_user": "New user",
            "time_shift": "Time shift"
        },
        "editing": {
            "bulk_update_failed": "Could not update the records.",
            "bulk_updated": "{0} records successfully updated.",
            "bulk_updating": "Updating {0} record...|Updating {0} records...",
            "search_field": "Filter fields...",
            "title": "Edit users"
        },
        "labels": {
            "bulk_edit": "Edit {0}s",
            "duplicate_items": "Duplicate {0}",
            "duplicate_items_message": "Are you sure you want to duplicate {0} {1}?|Are you sure you want to duplicate {0} {1}s?",
            "empty": "(empty)",
            "registration": {
                "api_v2": "API V2",
                "attending": "Attending",
                "backstage_import": "Backstage import",
                "invited": "Invited",
                "manually_loaded": "Manually loaded",
                "not_attending": "Not attending",
                "not_available": "N/A",
                "not_invited": "Not invited",
                "self_registered": "Self-registered",
                "unregistered": "Unregistered"
            }
        },
        "messages": {
            "bulk_edit": "The existing content will be replaced if the setting is turned on. Turn on the setting and leave the field empty to erase its existing content.",
            "bulk_edit_2": "Fields marked with an {0} can't be left blank.",
            "bulk_edit_intro": "You are editing {0} items. Only make changes to fields you want to modify.",
            "cannot_duplicate": "Selected {0}s could not be duplicated.",
            "close_reg": "Close reg.",
            "confirm_delete_job": "Do you want to delete this scheduled job?",
            "confirm_send_notification": "Do you want to send this notification to {0} users?",
            "confirm_update_leaderboard": "The leaderboard will be updated only if new points have been awarded since the last run. Proceed?",
            "days": "Days",
            "delete_fail": "The delete failed",
            "delete_success": "Successfully deleted",
            "editing_users": "You are editing {0} users.",
            "export": "Export",
            "exported": "Exported",
            "hours": "Hours",
            "items_deleted": "The selected item was removed.|The selected items were removed.",
            "items_deletion_prompt": "The selected items will be removed. Proceed?",
            "later": "Later",
            "meeting_locations_deletion_prompt": "Are you sure you want to delete this location?|Are you sure you want to delete these locations?",
            "meetings_deletion_prompt": "Are you sure you want to delete this meeting?|Are you sure you want to delete these meetings?",
            "minutes": "Minutes",
            "move": "Move",
            "no_leaderboard_to_export_description": "Currently there is no data for this leaderboard, please generate data first (Update Leaderboard Data button) before proceeding with the export.",
            "no_leaderboard_to_export_title": "Missing leaderboard data",
            "open_reg": "Open reg.",
            "other_fields": "Other fields",
            "person_fields": "Participant fields",
            "preparing_export": "Preparing your export...",
            "refresh": "Refresh",
            "refresh_fail": "The refresh failed",
            "refresh_success": "Successfully refreshed",
            "refresh_tooltip": "Refreshing jobs has to be done manually on localnodes",
            "select_fields": "Select fields",
            "send_notification": "Send notification",
            "send_now": "Send now",
            "sent_notification": "Notification sent",
            "shift": "Shift",
            "shift_by": "Shift by",
            "soft_delete": "Soft delete",
            "sooner": "Sooner",
            "timeshift_of": "Shifting the start and end times of",
            "update_leaderboard": "Update data",
            "updated": "Updated",
            "user_deletion_prompt": "The selected users will be deactivated. Proceed?",
            "users_status_changed": "User successfully updated|Users successfully updated"
        }
    },
    "main_menu": {
        "categories": {
            "analytics": "Analytics",
            "content": "Content",
            "dashboard": "Dashboard",
            "design": "Design",
            "dev": "Developer tools",
            "integrations": "Integrations",
            "legacy_dashboard": "Legacy Dashboard",
            "live": "Interactivity",
            "marketplace": "Marketplace",
            "onsite_logistic": "Onsite Logistics",
            "other": "Other",
            "settings": "Settings"
        },
        "devtools": "Developer tools",
        "devtools_bstg_icons": "Backstage icons",
        "devtools_doc_editor": "Document editor",
        "externals": {
            "event_api": "Workspace API"
        },
        "help": {
            "kb": "Knowledge base",
            "news": "What's new",
            "start": "Getting started",
            "support": "Support"
        },
        "logo_tooltip": "See all workspaces",
        "preview_app_tooltip": "Preview the app",
        "preview_content_hub_tooltip": "Preview the content hub",
        "quick_links": {
            "create_event": "Create workspace",
            "manage_apps": "Manage apps",
            "manage_organization": "Manage {0}",
            "manage_organizations": "Manage organizations",
            "manage_profile": "Manage your profile",
            "workspaces": "Workspaces"
        },
        "user": {
            "logout": "Logout",
            "organizations": "Organizations",
            "profile": "Your profile",
            "switch": "Switch to Backstage classic",
            "user_menu": "User Menu"
        }
    },
    "media": {
        "list": {
            "estimating_processing": "Estimating processing time",
            "failed": "Upload failed",
            "processing": "Processing...",
            "processing_over_soon": "Your video will be available shortly",
            "processing_taking_too_long": "Processing is taking longer than expected, if this persists please contact support",
            "remaining": "remaining"
        },
        "uploads": {
            "cancel_all": "Cancel all",
            "confirm_abort": "Are you sure you want to abort the upload of {0}?",
            "confirm_abort_all": "Are you sure you want to abort the upload?|Are you sure you want to abort the upload of {0} files?",
            "constraints_size": "Max file size {0}.",
            "constraints_type": "File type: {0}.|File types: {0}.",
            "file_too_big": "File too big",
            "file_type_not_accepted": "File type not accepted",
            "items_uploaded": "{0} item uploaded|{0} of {1} items uploaded",
            "messages": {
                "upload_failed": "Could not complete the upload due to an error. Please try again in a few minutes or contact the support."
            },
            "starting_upload": "Starting the upload...",
            "upload": "Upload"
        }
    },
    "meetings": {
        "calendar": {
            "cards": {
                "acceptance": "Accepted by {0}/{1}"
            },
            "filters": {
                "clear": "Clear selection",
                "daily_sessions": "Main event agenda",
                "locations": "Locations",
                "show_daily_sessions": "Show sessions from the main agenda",
                "title": "Display calendars",
                "users": "Users"
            }
        },
        "details": {
            "acceptance": "Acceptance",
            "actions": {
                "cancel": "Cancel and notify",
                "cancel_no_notify": "Cancel without notifying",
                "edit_meeting": "Meeting details"
            },
            "add_external_pax": "Add external participant +",
            "attendance": "Attendance",
            "cancel_meeting": "Cancel meeting",
            "cancellation_prompt": "Are you sure you want to cancel this meeting? Participants will be notified about cancellation.",
            "cancellation_prompt_title": "Cancel meeting",
            "date_and_time": "Date and time",
            "delete": "Cancel meeting",
            "description": "Meeting description",
            "info_popover": {
                "agenda_items": "Agenda items: main event agenda sessions",
                "confirmed": "Confirmed: meaning accepted by at least 2 people",
                "declined": "Declined: declined by all the participants",
                "invited": "Invited: invite sent, awaiting for acceptance by at least 1 participant",
                "meeting_statuses": "Meeting statuses:",
                "other_titles": "Other tiles:",
                "unavailable_slots": "Unavailable slots: out of location availability or blocked user time"
            },
            "location": "Location",
            "organized_by": "Organized by",
            "organizer": "Organizer",
            "participants": "Participant|Participants",
            "status": "Meeting status",
            "statuses": {
                "accepted": "Accepted",
                "agenda": "Agenda",
                "confirmed": "Confirmed",
                "declined": "Declined",
                "invited": "Invited",
                "legend": "Meeting status:",
                "pending": "Pending",
                "unavailable": "Unavailable"
            },
            "title": "Title"
        },
        "form": {
            "hints": {
                "meeting_location": "You have to select a time, the duration, an organizer, and at least one participant.",
                "missing_propsect_email": "Email is missing from the profile. Please edit the profile to schedule the meeting.",
                "missing_propsect_email_list": "One or more participants has missing Email."
            },
            "location_date_time_slot": "Date and time range{0}"
        },
        "messages": {
            "deleted_success": "All selected meetings have been successfully deleted.",
            "locations_deleted_success": "All selected locations have been successfully deleted.",
            "meeting_cancel_success": "The meeting has been successfully cancelled.",
            "save_success": "Settings have been successfully saved."
        },
        "requests": {
            "actions": {
                "reject-request": "Reject request",
                "request-availability": "Request availability",
                "schedule-meeting": "Schedule meeting"
            },
            "dialog": {
                "reject-request": {
                    "text": "Are you sure you want to reject the meeting request(s)? You can choose to notify the requester and all invitees who provided their availability.",
                    "title": "Reject meeting(s)",
                    "with_notification": "Reject and notify",
                    "without_notification": "Reject without notification"
                },
                "request-availability": {
                    "send": "Send request",
                    "text": "Are you sure you would like to send out a request for availability to the given participants?",
                    "title": "Send availability request email"
                }
            },
            "notify": {
                "availability_request_sent_fail": "Failed to request availability.",
                "availability_request_sent_success": "Email(s) sent successfully",
                "direct_email_sent_failure": "Email could not be sent to {0}. Please try again later or contact the support.",
                "direct_email_sent_success": "Email sent successfully to {0}",
                "invite_url_copied": "Unique form link copied",
                "meeting_created_fail": "Failed to create the meeting. Please try again later or contact the support.",
                "meeting_created_success": "The meeting request has been converted to a meeting and will now appear in the meeting list",
                "rejected_fail": "Failed to reject the meeting request(s).",
                "rejected_success": "Meeting request(s) rejected",
                "rejected_with_notification_fail": "Failed to reject the meeting request(s) and send email.",
                "rejected_with_notification_success": "Meeting request(s) rejected and email sent",
                "saved_and_availability_request_sent": "Meeting request created and email sent to participants to request their availability"
            },
            "participants": {
                "availability": {
                    "available": "Available",
                    "awaiting": "Awaiting availability",
                    "copy_link_aria_label": "Copy availability link",
                    "not-available": "Not available",
                    "send_email_aria_label": "Re-send availability email"
                }
            },
            "status": {
                "section": {
                    "description": {
                        "awaiting": "Once at least one participant provides their availability, you will be able to schedule a meeting from this meeting request.",
                        "draft_line1": "Send an email to the selected participants to request their availability.",
                        "draft_line2": "The available time slots for participants are determined by the organizer's schedule.",
                        "draft_line3": "The template send can be found in the Settings > Communication"
                    },
                    "email_sent_time": "Invitation sent",
                    "meeting_scheduled_time": "Scheduled time",
                    "rejected_time": "Rejection time",
                    "title": "Meeting request status"
                },
                "values": {
                    "accepted": "Accepted",
                    "awaiting": "Awaiting",
                    "confirmed": "Meeting scheduled",
                    "draft": "Draft",
                    "pending": "Pending",
                    "ready": "To be scheduled",
                    "rejected": "Rejected"
                }
            },
            "timeslots": {
                "already-booked": "Slot collision!",
                "available": "Available",
                "available-rooms": "{0} available room | {0} available rooms",
                "empty": "As participants respond, their availability will be displayed here.",
                "hint": "Please pick one of the proposed time slots"
            }
        },
        "settings": {
            "actions": {
                "export_locations": "Locations",
                "new_location": "New location"
            },
            "communication": {
                "meeting_planner": "Meeting planner notification",
                "meetings": "Meetings",
                "meetings_requests": "Meetings requests",
                "title": "Communication"
            },
            "error_loading": "Could not load current settings. Please try again later or contact the support.",
            "locations": {
                "actions": {
                    "add_range": "Add new range",
                    "add_time_slot_disabled": "The last hour reached the end of the day. If you want to add more time slots, please adjust previous ranges.",
                    "copy_times": "Copy time slots"
                },
                "configuration": {
                    "allow_custom_locations": "Allow custom location input when creating a meeting",
                    "allow_custom_locations_hint": "Custom location will be added to your calendar view at the end of the list",
                    "allow_virtual_meetings": "Allow virtual meetings",
                    "allow_virtual_meetings_hint": "The link to the meeting will be automatically added to the invite. If enabled, you can turn any location into a virtual one, with the capacity set to 20 participants by default.",
                    "custom_locations": "Custom locations",
                    "title": "General",
                    "virtual_meetings": "Virtual meetings"
                },
                "title": "Location options"
            },
            "permissions": {
                "title": "Permissions"
            },
            "schedule": {
                "title": "Schedule rules"
            },
            "timings": {
                "title": "Duration"
            }
        }
    },
    "new_design_tour": {
        "title": "Welcome to the new Backstage!"
    },
    "packages": {
        "live_streams": {
            "label_word_cloud_blocked_words": "Word cloud - blocked words"
        }
    },
    "pager": {
        "no_more": "All {0} have been loaded.",
        "no_results": "No results to display.",
        "templates": "templates"
    },
    "support": {
        "deleted_docs": {
            "filter_by_type": "Filter by fp_type",
            "headers": {
                "deleted_by": "Deleted by",
                "deleted_timestamp": "Deleted at",
                "deleted_timestamp_tz": "Deleted at ({0})",
                "name": "Name",
                "original_fp_type": "Fp Type",
                "time_info": "Dates are expressed in your local time."
            },
            "messages": {
                "could_not_load": "Could not load the deleted documents list. Check the console for more details.",
                "could_not_restore": "Could not restore the selected document(s). Check the console for more info.",
                "restore_success": "Successfully restored {count} document.|Successfully restored {count} documents."
            },
            "no_docs": "There are no deleted documents.",
            "restore": "Restore",
            "title": "Deleted documents"
        }
    },
    "supported-browsers": {
        "body": "Unfortunately the browser you are currently using doesn't support the required technology for Studio to work. Download one of the following browsers and join again.",
        "title": "You should update your browser"
    },
    "targeting": {
        "conditions_editor": {
            "add_rule": "Add rule",
            "affected_pax_title": "Affected participants ({0})",
            "applied": "Applied",
            "emailing": {
                "do_not_send_to": "Don't send to",
                "send_to": "Send to"
            },
            "fp_ext_id": "Unique identifier",
            "hidden_to": "Hidden to",
            "intro": "Choose who will be able to see this in your audience.",
            "pax_name": "Participant name",
            "select_field": "Select field",
            "select_value": "Select value",
            "show_affected": "Show affected participants ({0})",
            "targeting_has_errors": "These targeting rules contain errors",
            "text_for_hidden_to": "This will be hidden from attendees who match the below rule(s).",
            "text_for_visible_to": "This will be visible to attendees who match the below rule(s).",
            "type_value": "Type value",
            "visible_to": "Visible to",
            "visible_to_everyone": "Visible to everyone"
        },
        "too_many_pax": "Due to the large number of targeted participants, this will only show a partial list"
    },
    "templates": {
        "create_template": "New template",
        "edit_template": "Edit template",
        "global": "global",
        "learn_more": "Learn more",
        "no_template_edit": "It looks like the template you requested to edit does not exist.",
        "no_templates": "It looks like you don’t have any templates, contact your organization administrator if you need to create a template.",
        "no_templates_admin": "It looks like you don't have any templates here. Get started by creating your first template now!",
        "private": "private",
        "public": "public",
        "settings": {
            "save": "Save Changes",
            "save_failure": "Unable to save settings, try again later",
            "save_success": "Settings saved"
        },
        "title": "Templates",
        "unknown": "unknown",
        "view_details": "Details",
        "website_link": "https://spotme.com/templates?source=bstg",
        "website_link_label": "Browse SpotMe templates",
        "welcome": "Welcome!"
    },
    "theme": {
        "editor": {
            "actions": {
                "quick_setup": "Quick setup"
            },
            "appearance": {
                "custom": "Custom",
                "dark": "Dark",
                "label": "Appearance",
                "light": "Light"
            },
            "colours": {
                "enable_icons_tint": "Custom icons color (header / menu)",
                "enable_icons_tint_hint": "Enable this to change the colors of the Header and Menu icons. Disable this to use the default colors from the icons set.",
                "header_bg": "Header background / primary brand colour",
                "header_bg_tip": "Applied throughout the app for selections and graphic elements.",
                "header_fg": "Header icons / initials",
                "header_fg_custom": "Header text & icons / initials",
                "header_fg_tip": "Enable the \"Custom icons color (header / menu)\" option in order to see this color applied to the header icons. Initials will always use the color selected here.",
                "header_fg_tip_custom": "Enable the \"Custom icons color (header / menu)\" option in order to see this color applied to the header icons. Header text and initials will always use the color selected here.",
                "home_bg": "Homepage background",
                "menu_fg": "Menu text",
                "menu_icons": "Menu icons",
                "menu_section_separator_bg": "Menu's section separator background",
                "menu_section_separator_fg": "Menu's section separator text",
                "menu_username": "Menu - user's name",
                "menu_workspace_name": "Menu - workspace name",
                "navigation_selected": "Navigation - selected item",
                "primary_bg": "Primary brand colour",
                "session_reg_checkmark_bg": "Session registration checkmark"
            },
            "messages": {
                "save_failed": "Could not save the theme. Try again later or contact the support.",
                "save_success": "The theme has been successfully saved.",
                "wizard_warn": "Saving a theme from the wizard will overwrite your current theme."
            },
            "mobile_only": "Mobile app only",
            "previews": {
                "mobile": "Mobile app",
                "mobile_home": "Home",
                "mobile_menu": "Menu",
                "web": "Web app"
            },
            "settings": {
                "less": "Less settings",
                "more": "More settings"
            },
            "title": "Theme editor"
        },
        "graphics": {
            "app_icon": {
                "hint": "File type: {0}. Max file size 1MB. Recommended resolution 512x512 px.",
                "label": "In-app icon",
                "tip": "The image will be used for the favicon in the browser and for the in-app icon"
            },
            "messages": {
                "could_not_delete_asset": "Could not delete the asset. Try again later or contact the support.",
                "could_not_save_asset": "Could not save the asset. Try again later or contact the support.",
                "could_remove_asset": "Icon successfully removed.",
                "could_save_asset": "Asset successfully saved"
            }
        }
    },
    "validations": {
        "container_exists": "This name is already in use.",
        "container_name_too_short": "The name should be at least {0} characters long.",
        "container_server_error": "Name can't be checked at the moment, please try again later.",
        "display_name_too_long": "Name is longer than the maximum {0} characters, it will appear as \"{1}...\" on your video.",
        "email_set": "One or more email addresses are not valid",
        "foreign_reference": "The referenced object is required.",
        "hex_color": "Must be a valid HEX color.",
        "image_cropping_completed": "You have to complete editing.",
        "image_does_not_fit": "Could not save. Please crop the image",
        "invalid_field_name": "Invalid field name",
        "name_exists": "This name already exists.",
        "no_empty_field": "The field cannot be left empty",
        "overwrites": {
            "messages": {
                "confirmed": "The {_field_} does not match"
            },
            "names": {
                "answerLimit": "answer limit",
                "branded_app": "app container",
                "characterLimit": "character limit",
                "confirmPassword": "password confirmation",
                "design_0_design_id": "badge design",
                "design_0_targeting": "targeting",
                "design_1_design_id": "badge design",
                "design_1_targeting": "targeting",
                "design_2_design_id": "badge design",
                "design_2_targeting": "targeting",
                "design_3_design_id": "badge design",
                "design_3_targeting": "targeting",
                "design_4_design_id": "badge design",
                "design_4_targeting": "targeting",
                "email_domains_block": "email domains block list",
                "email_domains_pass": "email domains pass list",
                "fname": "first name",
                "lname": "last name",
                "min_registered_sessions": "minimum number of session registrations",
                "newPassword": "new password",
                "pax_print_quantity": "print quantity",
                "registrationUrl": "registration URL",
                "registration_path": "registration URL",
                "rsvp_user_limit": "attendance capacity",
                "self_registered_user_limit": "registration capacity",
                "terms_of_service": "terms of service"
            }
        },
        "reg_url_exists": "This URL is already in use. Please choose another one.",
        "secure_scheme": "The {0} field must use a secure protocol.",
        "time_minsec": "The {0} field must be a valid time in the formats SS or MM:SS.",
        "url": "The {0} must be a valid URL.",
        "url_path_too_short": "The URL path should be at least {0} characters long.",
        "url_server_error": "URL can't be checked at the moment, please try again later."
    },
    "video_library": {
        "actions": {
            "download_analytics": "Download analytics"
        },
        "list": {
            "empty": "No videos found, you need to upload some first.",
            "headers": {
                "file_size": "File size",
                "thumbnail": "Thumbnail",
                "title": "Title",
                "upload_date": "Upload date"
            },
            "rename": "Rename"
        },
        "stats": {
            "total_videos": "Videos"
        },
        "thumbnail": {
            "add_from_list": "THUMBNAIL"
        }
    },
    "webhooks": {
        "actions": {
            "create": "Add a webhook",
            "edit": "Edit webhook",
            "prompt_delete": "Are you sure you want to delete this webhook? This action can't be undone.",
            "save": "Save"
        },
        "form": {
            "actions": "Actions to trigger this webhook",
            "active": "Active",
            "active_hint": "Only active webhooks receive actions.",
            "label": "Label",
            "secret": "Secret",
            "url": "Endpoint URL",
            "url_placeholder": "https://example.com/api/v1/..."
        },
        "intro_empty": "Webhooks allow external services to be notified when certain actions happen. When the specified action happens, we’ll send a POST request to each of the URLs you provide.",
        "intro_full": "Webhooks allow external services to be notified when certain actions happen. {0}",
        "intro_full_link_label": "Learn more"
    },
    "webinars": {
        "attendees": "Attendees",
        "automatedEmails": {
            "hint": "If you are using a custom registration page or want more control over emails sent to attendees, you can disable built-in automated emails and have them sent from your Marketing Automation System instead.",
            "label": "Send automated reminder and replay email",
            "title": "Automated emails",
            "warning": "Automated emails are disabled.",
            "warning_description": "Reminder and replay emails are not being sent to attendees."
        },
        "convert": "Convert to template",
        "create": "New webinar",
        "create_new_webinar": "New webinar",
        "creating_webinar": "Creating webinar…",
        "creation": {
            "from_scratch": "Create webinar from scratch",
            "last_step": "Create",
            "no_template_found": "No template available. You can easily convert an existing webinar into a template, or create a template from scratch.",
            "steps": {
                "design": "Design",
                "details": "Webinar details",
                "general": "General",
                "integrations": "Integrations",
                "interactivity": "Interactivity",
                "post_event_form": "Post event form",
                "registration_page": "Registration page",
                "select_template": "Choose template"
            },
            "title": "New webinar"
        },
        "duration": "Duration in minutes",
        "empty": "We couldn't find anything but potential here, get started by creating your first webinar now!",
        "form": {
            "background": "Background",
            "background_hint": "File types: PNG, JPEG. Max file size 5MB. Recommended resolution 1920x1080.",
            "banner": "Banner",
            "banner_hint": "File types: PNG, JPEG. Max file size is 10MB. The recommended resolution is 2880x692.",
            "brand_color": "Brand color",
            "brand_color_hint": "Brand color is applied on the registration page, automated emails and the webinar.",
            "branded_app": "App container",
            "branded_app_placeholder": "Select a container app",
            "description": "Description",
            "duration": "Duration in minutes",
            "icon": "Webinar icon",
            "icon_hint": "This icon will be used in registration, emails and on the webinar page. Accepted file types: PNG and JPEG. Max file size: 2MB.",
            "name": "Webinar name",
            "organization": "Organization",
            "organization_placeholder": "Select an organization",
            "privacy_documents": "Privacy policies",
            "privacy_documents_placeholder": "Choose polices",
            "privacy_intro": "Users will have to accept requirements specified in the documents selected below before accessing the workspace. At least one privacy policy must be selected to create a workspace.",
            "registration_fields": "Form fields",
            "servers_location": "Data location",
            "servers_location_placeholder": "Select a server location",
            "start_time": "Starts at",
            "timezone": "Time zone",
            "timezone_placeholder": "Time zone",
            "url": "Webinar URL"
        },
        "integrations": {
            "veeva": "veeva"
        },
        "name": "Webinar name",
        "new_webinar": "New webinar",
        "registrations": "Registrations",
        "start_time": "Starts at",
        "status": "Status",
        "title": "Webinars"
    },
    "workspaces": {
        "lifecycle": {
            "preprod": "Preproduction",
            "prod": "Production"
        }
    }
}
