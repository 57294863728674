
/**
 * WARNING - THIS FILE IS AUTOGENERATED
 */

/** @const {string} SPOTICONS_5_F48562_E9 The name of the icons font */
export const CSS_FONT_NAME = 'SPOTICONS_5_F48562_E9';

/** @const {string} FONT_ICON_ACTIVITY */
export const FONT_ICON_ACTIVITY = '';

/** @const {string} FONT_ICON_AIRPLAY */
export const FONT_ICON_AIRPLAY = '';

/** @const {string} FONT_ICON_ALERT_CIRCLE_FILL */
export const FONT_ICON_ALERT_CIRCLE_FILL = '';

/** @const {string} FONT_ICON_ALERT_CIRCLE */
export const FONT_ICON_ALERT_CIRCLE = '';

/** @const {string} FONT_ICON_ALERT_OCTAGON */
export const FONT_ICON_ALERT_OCTAGON = '';

/** @const {string} FONT_ICON_ALERT_TRIANGLE */
export const FONT_ICON_ALERT_TRIANGLE = '';

/** @const {string} FONT_ICON_ALIGN_CENTER */
export const FONT_ICON_ALIGN_CENTER = '';

/** @const {string} FONT_ICON_ALIGN_JUSTIFY */
export const FONT_ICON_ALIGN_JUSTIFY = '';

/** @const {string} FONT_ICON_ALIGN_LEFT */
export const FONT_ICON_ALIGN_LEFT = '';

/** @const {string} FONT_ICON_ALIGN_RIGHT */
export const FONT_ICON_ALIGN_RIGHT = '';

/** @const {string} FONT_ICON_ANCHOR */
export const FONT_ICON_ANCHOR = '';

/** @const {string} FONT_ICON_APERTURE */
export const FONT_ICON_APERTURE = '';

/** @const {string} FONT_ICON_ARROW_DOWN_ARROW_UP */
export const FONT_ICON_ARROW_DOWN_ARROW_UP = '';

/** @const {string} FONT_ICON_ARROW_DOWN_LEFT */
export const FONT_ICON_ARROW_DOWN_LEFT = '';

/** @const {string} FONT_ICON_ARROW_DOWN_RIGHT */
export const FONT_ICON_ARROW_DOWN_RIGHT = '';

/** @const {string} FONT_ICON_ARROW_DOWN */
export const FONT_ICON_ARROW_DOWN = '';

/** @const {string} FONT_ICON_ARROW_LEFT */
export const FONT_ICON_ARROW_LEFT = '';

/** @const {string} FONT_ICON_ARROW_RIGHT */
export const FONT_ICON_ARROW_RIGHT = '';

/** @const {string} FONT_ICON_ARROW_UP_LEFT */
export const FONT_ICON_ARROW_UP_LEFT = '';

/** @const {string} FONT_ICON_ARROW_UP_RIGHT */
export const FONT_ICON_ARROW_UP_RIGHT = '';

/** @const {string} FONT_ICON_ARROW_UP */
export const FONT_ICON_ARROW_UP = '';

/** @const {string} FONT_ICON_AT_SIGN */
export const FONT_ICON_AT_SIGN = '';

/** @const {string} FONT_ICON_AWARD */
export const FONT_ICON_AWARD = '';

/** @const {string} FONT_ICON_BACKWARD */
export const FONT_ICON_BACKWARD = '';

/** @const {string} FONT_ICON_BADGE */
export const FONT_ICON_BADGE = '';

/** @const {string} FONT_ICON_BAR_CHART_2 */
export const FONT_ICON_BAR_CHART_2 = '';

/** @const {string} FONT_ICON_BAR_CHART */
export const FONT_ICON_BAR_CHART = '';

/** @const {string} FONT_ICON_BATTERY_CHARGING */
export const FONT_ICON_BATTERY_CHARGING = '';

/** @const {string} FONT_ICON_BATTERY */
export const FONT_ICON_BATTERY = '';

/** @const {string} FONT_ICON_BELL_OFF */
export const FONT_ICON_BELL_OFF = '';

/** @const {string} FONT_ICON_BELL */
export const FONT_ICON_BELL = '';

/** @const {string} FONT_ICON_BLUETOOTH */
export const FONT_ICON_BLUETOOTH = '';

/** @const {string} FONT_ICON_BOOK */
export const FONT_ICON_BOOK = '';

/** @const {string} FONT_ICON_BOOKMARK */
export const FONT_ICON_BOOKMARK = '';

/** @const {string} FONT_ICON_BOX */
export const FONT_ICON_BOX = '';

/** @const {string} FONT_ICON_BRIEFCASE */
export const FONT_ICON_BRIEFCASE = '';

/** @const {string} FONT_ICON_BUILDING */
export const FONT_ICON_BUILDING = '';

/** @const {string} FONT_ICON_BURGER */
export const FONT_ICON_BURGER = '';

/** @const {string} FONT_ICON_CALENDAR_AGENDA */
export const FONT_ICON_CALENDAR_AGENDA = '';

/** @const {string} FONT_ICON_CAMERA_OFF */
export const FONT_ICON_CAMERA_OFF = '';

/** @const {string} FONT_ICON_CAMERA */
export const FONT_ICON_CAMERA = '';

/** @const {string} FONT_ICON_CAPTIONS_ON */
export const FONT_ICON_CAPTIONS_ON = '';

/** @const {string} FONT_ICON_CAPTIONS */
export const FONT_ICON_CAPTIONS = '';

/** @const {string} FONT_ICON_CAST */
export const FONT_ICON_CAST = '';

/** @const {string} FONT_ICON_CHAT */
export const FONT_ICON_CHAT = '';

/** @const {string} FONT_ICON_CHECK_CIRCLE */
export const FONT_ICON_CHECK_CIRCLE = '';

/** @const {string} FONT_ICON_CHECK_FILL */
export const FONT_ICON_CHECK_FILL = '';

/** @const {string} FONT_ICON_CHECK_SQUARE */
export const FONT_ICON_CHECK_SQUARE = '';

/** @const {string} FONT_ICON_CHECK */
export const FONT_ICON_CHECK = '';

/** @const {string} FONT_ICON_CHEVRON_DOWN */
export const FONT_ICON_CHEVRON_DOWN = '';

/** @const {string} FONT_ICON_CHEVRON_LEFT */
export const FONT_ICON_CHEVRON_LEFT = '';

/** @const {string} FONT_ICON_CHEVRON_RIGHT */
export const FONT_ICON_CHEVRON_RIGHT = '';

/** @const {string} FONT_ICON_CHEVRON_UP */
export const FONT_ICON_CHEVRON_UP = '';

/** @const {string} FONT_ICON_CHEVRONS_DOWN */
export const FONT_ICON_CHEVRONS_DOWN = '';

/** @const {string} FONT_ICON_CHEVRONS_LEFT */
export const FONT_ICON_CHEVRONS_LEFT = '';

/** @const {string} FONT_ICON_CHEVRONS_RIGHT */
export const FONT_ICON_CHEVRONS_RIGHT = '';

/** @const {string} FONT_ICON_CHEVRONS_UP */
export const FONT_ICON_CHEVRONS_UP = '';

/** @const {string} FONT_ICON_CHROME */
export const FONT_ICON_CHROME = '';

/** @const {string} FONT_ICON_CIRCLE */
export const FONT_ICON_CIRCLE = '';

/** @const {string} FONT_ICON_CLIPBOARD */
export const FONT_ICON_CLIPBOARD = '';

/** @const {string} FONT_ICON_CLOCK */
export const FONT_ICON_CLOCK = '';

/** @const {string} FONT_ICON_CLOUD_DRIZZLE */
export const FONT_ICON_CLOUD_DRIZZLE = '';

/** @const {string} FONT_ICON_CLOUD_LIGHTNING */
export const FONT_ICON_CLOUD_LIGHTNING = '';

/** @const {string} FONT_ICON_CLOUD_OFF */
export const FONT_ICON_CLOUD_OFF = '';

/** @const {string} FONT_ICON_CLOUD_RAIN */
export const FONT_ICON_CLOUD_RAIN = '';

/** @const {string} FONT_ICON_CLOUD_SNOW */
export const FONT_ICON_CLOUD_SNOW = '';

/** @const {string} FONT_ICON_CLOUD */
export const FONT_ICON_CLOUD = '';

/** @const {string} FONT_ICON_COMMAND */
export const FONT_ICON_COMMAND = '';

/** @const {string} FONT_ICON_COMPASS */
export const FONT_ICON_COMPASS = '';

/** @const {string} FONT_ICON_COPY */
export const FONT_ICON_COPY = '';

/** @const {string} FONT_ICON_CORNER_DOWN_LEFT */
export const FONT_ICON_CORNER_DOWN_LEFT = '';

/** @const {string} FONT_ICON_CORNER_DOWN_RIGHT */
export const FONT_ICON_CORNER_DOWN_RIGHT = '';

/** @const {string} FONT_ICON_CORNER_LEFT_DOWN */
export const FONT_ICON_CORNER_LEFT_DOWN = '';

/** @const {string} FONT_ICON_CORNER_LEFT_UP */
export const FONT_ICON_CORNER_LEFT_UP = '';

/** @const {string} FONT_ICON_CORNER_RIGHT_DOWN */
export const FONT_ICON_CORNER_RIGHT_DOWN = '';

/** @const {string} FONT_ICON_CORNER_RIGHT_UP */
export const FONT_ICON_CORNER_RIGHT_UP = '';

/** @const {string} FONT_ICON_CORNER_UP_LEFT */
export const FONT_ICON_CORNER_UP_LEFT = '';

/** @const {string} FONT_ICON_CORNER_UP_RIGHT */
export const FONT_ICON_CORNER_UP_RIGHT = '';

/** @const {string} FONT_ICON_CPU */
export const FONT_ICON_CPU = '';

/** @const {string} FONT_ICON_CREDIT_CARD */
export const FONT_ICON_CREDIT_CARD = '';

/** @const {string} FONT_ICON_CROSSHAIR */
export const FONT_ICON_CROSSHAIR = '';

/** @const {string} FONT_ICON_DASHBOARD */
export const FONT_ICON_DASHBOARD = '';

/** @const {string} FONT_ICON_DATABASE */
export const FONT_ICON_DATABASE = '';

/** @const {string} FONT_ICON_DELETE */
export const FONT_ICON_DELETE = '';

/** @const {string} FONT_ICON_DISC */
export const FONT_ICON_DISC = '';

/** @const {string} FONT_ICON_DOOR */
export const FONT_ICON_DOOR = '';

/** @const {string} FONT_ICON_DOWNLOAD_CLOUD */
export const FONT_ICON_DOWNLOAD_CLOUD = '';

/** @const {string} FONT_ICON_DOWNLOAD */
export const FONT_ICON_DOWNLOAD = '';

/** @const {string} FONT_ICON_DROPLET */
export const FONT_ICON_DROPLET = '';

/** @const {string} FONT_ICON_EARPODS_BLUETOOTH */
export const FONT_ICON_EARPODS_BLUETOOTH = '';

/** @const {string} FONT_ICON_EARTH */
export const FONT_ICON_EARTH = '';

/** @const {string} FONT_ICON_EDIT_2 */
export const FONT_ICON_EDIT_2 = '';

/** @const {string} FONT_ICON_EDIT_3 */
export const FONT_ICON_EDIT_3 = '';

/** @const {string} FONT_ICON_EDIT */
export const FONT_ICON_EDIT = '';

/** @const {string} FONT_ICON_EMAIL_TEMPLATE_COPY */
export const FONT_ICON_EMAIL_TEMPLATE_COPY = '';

/** @const {string} FONT_ICON_EMAIL_TEMPLATE_PASTE */
export const FONT_ICON_EMAIL_TEMPLATE_PASTE = '';

/** @const {string} FONT_ICON_EXTERNAL_LINK */
export const FONT_ICON_EXTERNAL_LINK = '';

/** @const {string} FONT_ICON_EXTERNAL */
export const FONT_ICON_EXTERNAL = '';

/** @const {string} FONT_ICON_EYE_2 */
export const FONT_ICON_EYE_2 = '';

/** @const {string} FONT_ICON_EYE_OFF */
export const FONT_ICON_EYE_OFF = '';

/** @const {string} FONT_ICON_EYE */
export const FONT_ICON_EYE = '';

/** @const {string} FONT_ICON_FACEBOOK */
export const FONT_ICON_FACEBOOK = '';

/** @const {string} FONT_ICON_FAST_FORWARD */
export const FONT_ICON_FAST_FORWARD = '';

/** @const {string} FONT_ICON_FEATHER */
export const FONT_ICON_FEATHER = '';

/** @const {string} FONT_ICON_FEED */
export const FONT_ICON_FEED = '';

/** @const {string} FONT_ICON_FILE_MINUS */
export const FONT_ICON_FILE_MINUS = '';

/** @const {string} FONT_ICON_FILE_PLUS */
export const FONT_ICON_FILE_PLUS = '';

/** @const {string} FONT_ICON_FILE_TEXT */
export const FONT_ICON_FILE_TEXT = '';

/** @const {string} FONT_ICON_FILE */
export const FONT_ICON_FILE = '';

/** @const {string} FONT_ICON_FILM */
export const FONT_ICON_FILM = '';

/** @const {string} FONT_ICON_FILTER */
export const FONT_ICON_FILTER = '';

/** @const {string} FONT_ICON_FLAG */
export const FONT_ICON_FLAG = '';

/** @const {string} FONT_ICON_FLASH */
export const FONT_ICON_FLASH = '';

/** @const {string} FONT_ICON_FOLDER */
export const FONT_ICON_FOLDER = '';

/** @const {string} FONT_ICON_FULLSCREEN_OUT */
export const FONT_ICON_FULLSCREEN_OUT = '';

/** @const {string} FONT_ICON_FULSCREEN_IN */
export const FONT_ICON_FULSCREEN_IN = '';

/** @const {string} FONT_ICON_GAUGE */
export const FONT_ICON_GAUGE = '';

/** @const {string} FONT_ICON_GEAR */
export const FONT_ICON_GEAR = '';

/** @const {string} FONT_ICON_GITHUB */
export const FONT_ICON_GITHUB = '';

/** @const {string} FONT_ICON_GLOBE */
export const FONT_ICON_GLOBE = '';

/** @const {string} FONT_ICON_GRID */
export const FONT_ICON_GRID = '';

/** @const {string} FONT_ICON_HANG_UP */
export const FONT_ICON_HANG_UP = '';

/** @const {string} FONT_ICON_HASH */
export const FONT_ICON_HASH = '';

/** @const {string} FONT_ICON_HEADPHONES */
export const FONT_ICON_HEADPHONES = '';

/** @const {string} FONT_ICON_HEART */
export const FONT_ICON_HEART = '';

/** @const {string} FONT_ICON_HELP_CHAT */
export const FONT_ICON_HELP_CHAT = '';

/** @const {string} FONT_ICON_HELP */
export const FONT_ICON_HELP = '';

/** @const {string} FONT_ICON_HOME */
export const FONT_ICON_HOME = '';

/** @const {string} FONT_ICON_IMAGE */
export const FONT_ICON_IMAGE = '';

/** @const {string} FONT_ICON_IN_PERSON */
export const FONT_ICON_IN_PERSON = '';

/** @const {string} FONT_ICON_INBOX */
export const FONT_ICON_INBOX = '';

/** @const {string} FONT_ICON_INFO_FILL */
export const FONT_ICON_INFO_FILL = '';

/** @const {string} FONT_ICON_INFO */
export const FONT_ICON_INFO = '';

/** @const {string} FONT_ICON_INSTAGRAM */
export const FONT_ICON_INSTAGRAM = '';

/** @const {string} FONT_ICON_INTERNAL */
export const FONT_ICON_INTERNAL = '';

/** @const {string} FONT_ICON_INTERPREFY */
export const FONT_ICON_INTERPREFY = '';

/** @const {string} FONT_ICON_KEY */
export const FONT_ICON_KEY = '';

/** @const {string} FONT_ICON_LAYERS */
export const FONT_ICON_LAYERS = '';

/** @const {string} FONT_ICON_LAYOUT */
export const FONT_ICON_LAYOUT = '';

/** @const {string} FONT_ICON_LEAD */
export const FONT_ICON_LEAD = '';

/** @const {string} FONT_ICON_LIFE_BUOY */
export const FONT_ICON_LIFE_BUOY = '';

/** @const {string} FONT_ICON_LINK_2 */
export const FONT_ICON_LINK_2 = '';

/** @const {string} FONT_ICON_LINK */
export const FONT_ICON_LINK = '';

/** @const {string} FONT_ICON_LIST */
export const FONT_ICON_LIST = '';

/** @const {string} FONT_ICON_LOADER */
export const FONT_ICON_LOADER = '';

/** @const {string} FONT_ICON_LOCK */
export const FONT_ICON_LOCK = '';

/** @const {string} FONT_ICON_LOG_IN */
export const FONT_ICON_LOG_IN = '';

/** @const {string} FONT_ICON_LOG_OUT */
export const FONT_ICON_LOG_OUT = '';

/** @const {string} FONT_ICON_LOWER_THIRD */
export const FONT_ICON_LOWER_THIRD = '';

/** @const {string} FONT_ICON_MAGIC_STARS_OUTLINE */
export const FONT_ICON_MAGIC_STARS_OUTLINE = '';

/** @const {string} FONT_ICON_MAGIC_STARS */
export const FONT_ICON_MAGIC_STARS = '';

/** @const {string} FONT_ICON_MAGNET */
export const FONT_ICON_MAGNET = '';

/** @const {string} FONT_ICON_MAIL */
export const FONT_ICON_MAIL = '';

/** @const {string} FONT_ICON_MAP_PIN */
export const FONT_ICON_MAP_PIN = '';

/** @const {string} FONT_ICON_MAP */
export const FONT_ICON_MAP = '';

/** @const {string} FONT_ICON_MAXIMIZE_2 */
export const FONT_ICON_MAXIMIZE_2 = '';

/** @const {string} FONT_ICON_MAXIMIZE */
export const FONT_ICON_MAXIMIZE = '';

/** @const {string} FONT_ICON_MEDIA */
export const FONT_ICON_MEDIA = '';

/** @const {string} FONT_ICON_MENU */
export const FONT_ICON_MENU = '';

/** @const {string} FONT_ICON_MESSAGE_CIRCLE */
export const FONT_ICON_MESSAGE_CIRCLE = '';

/** @const {string} FONT_ICON_MESSAGE_SQUARE */
export const FONT_ICON_MESSAGE_SQUARE = '';

/** @const {string} FONT_ICON_MIC_OFF */
export const FONT_ICON_MIC_OFF = '';

/** @const {string} FONT_ICON_MIC_OUTLINE */
export const FONT_ICON_MIC_OUTLINE = '';

/** @const {string} FONT_ICON_MIC */
export const FONT_ICON_MIC = '';

/** @const {string} FONT_ICON_MINIMIZE_2 */
export const FONT_ICON_MINIMIZE_2 = '';

/** @const {string} FONT_ICON_MINIMIZE */
export const FONT_ICON_MINIMIZE = '';

/** @const {string} FONT_ICON_MINUS_CIRCLE */
export const FONT_ICON_MINUS_CIRCLE = '';

/** @const {string} FONT_ICON_MINUS_SQUARE */
export const FONT_ICON_MINUS_SQUARE = '';

/** @const {string} FONT_ICON_MINUS */
export const FONT_ICON_MINUS = '';

/** @const {string} FONT_ICON_MIXED */
export const FONT_ICON_MIXED = '';

/** @const {string} FONT_ICON_MONITOR */
export const FONT_ICON_MONITOR = '';

/** @const {string} FONT_ICON_MOON */
export const FONT_ICON_MOON = '';

/** @const {string} FONT_ICON_MORE_HORIZONTAL */
export const FONT_ICON_MORE_HORIZONTAL = '';

/** @const {string} FONT_ICON_MORE_VERTICAL */
export const FONT_ICON_MORE_VERTICAL = '';

/** @const {string} FONT_ICON_MOVE */
export const FONT_ICON_MOVE = '';

/** @const {string} FONT_ICON_MUSIC */
export const FONT_ICON_MUSIC = '';

/** @const {string} FONT_ICON_NAVIGATION_2 */
export const FONT_ICON_NAVIGATION_2 = '';

/** @const {string} FONT_ICON_NAVIGATION */
export const FONT_ICON_NAVIGATION = '';

/** @const {string} FONT_ICON_OCTAGON */
export const FONT_ICON_OCTAGON = '';

/** @const {string} FONT_ICON_OPEN */
export const FONT_ICON_OPEN = '';

/** @const {string} FONT_ICON_PACKAGE */
export const FONT_ICON_PACKAGE = '';

/** @const {string} FONT_ICON_PALETTE */
export const FONT_ICON_PALETTE = '';

/** @const {string} FONT_ICON_PAUSE_CIRCLE */
export const FONT_ICON_PAUSE_CIRCLE = '';

/** @const {string} FONT_ICON_PAUSE */
export const FONT_ICON_PAUSE = '';

/** @const {string} FONT_ICON_PEOPLE */
export const FONT_ICON_PEOPLE = '';

/** @const {string} FONT_ICON_PERCENT */
export const FONT_ICON_PERCENT = '';

/** @const {string} FONT_ICON_PHONE_CALL */
export const FONT_ICON_PHONE_CALL = '';

/** @const {string} FONT_ICON_PHONE_FORWARDED */
export const FONT_ICON_PHONE_FORWARDED = '';

/** @const {string} FONT_ICON_PHONE_INCOMING */
export const FONT_ICON_PHONE_INCOMING = '';

/** @const {string} FONT_ICON_PHONE_MISSED */
export const FONT_ICON_PHONE_MISSED = '';

/** @const {string} FONT_ICON_PHONE_OFF */
export const FONT_ICON_PHONE_OFF = '';

/** @const {string} FONT_ICON_PHONE_OUTGOING */
export const FONT_ICON_PHONE_OUTGOING = '';

/** @const {string} FONT_ICON_PHONE */
export const FONT_ICON_PHONE = '';

/** @const {string} FONT_ICON_PIE_CHART_2 */
export const FONT_ICON_PIE_CHART_2 = '';

/** @const {string} FONT_ICON_PIE_CHART */
export const FONT_ICON_PIE_CHART = '';

/** @const {string} FONT_ICON_PIN */
export const FONT_ICON_PIN = '';

/** @const {string} FONT_ICON_PLAY_CIRCLE */
export const FONT_ICON_PLAY_CIRCLE = '';

/** @const {string} FONT_ICON_PLAY */
export const FONT_ICON_PLAY = '';

/** @const {string} FONT_ICON_PLUS_CIRCLE */
export const FONT_ICON_PLUS_CIRCLE = '';

/** @const {string} FONT_ICON_PLUS_SQUARE */
export const FONT_ICON_PLUS_SQUARE = '';

/** @const {string} FONT_ICON_PLUS */
export const FONT_ICON_PLUS = '';

/** @const {string} FONT_ICON_POCKET */
export const FONT_ICON_POCKET = '';

/** @const {string} FONT_ICON_POWER */
export const FONT_ICON_POWER = '';

/** @const {string} FONT_ICON_PRESENTATIONS */
export const FONT_ICON_PRESENTATIONS = '';

/** @const {string} FONT_ICON_PRINTER */
export const FONT_ICON_PRINTER = '';

/** @const {string} FONT_ICON_QNA */
export const FONT_ICON_QNA = '';

/** @const {string} FONT_ICON_QR_CODE */
export const FONT_ICON_QR_CODE = '';

/** @const {string} FONT_ICON_QUESTION */
export const FONT_ICON_QUESTION = '';

/** @const {string} FONT_ICON_RADIO */
export const FONT_ICON_RADIO = '';

/** @const {string} FONT_ICON_RANKING */
export const FONT_ICON_RANKING = '';

/** @const {string} FONT_ICON_REFRESH_CCW */
export const FONT_ICON_REFRESH_CCW = '';

/** @const {string} FONT_ICON_REFRESH_CW */
export const FONT_ICON_REFRESH_CW = '';

/** @const {string} FONT_ICON_REMOTE */
export const FONT_ICON_REMOTE = '';

/** @const {string} FONT_ICON_REPEAT */
export const FONT_ICON_REPEAT = '';

/** @const {string} FONT_ICON_REPORT */
export const FONT_ICON_REPORT = '';

/** @const {string} FONT_ICON_RESTORE */
export const FONT_ICON_RESTORE = '';

/** @const {string} FONT_ICON_REWIND */
export const FONT_ICON_REWIND = '';

/** @const {string} FONT_ICON_ROTATE_CCW */
export const FONT_ICON_ROTATE_CCW = '';

/** @const {string} FONT_ICON_ROTATE_CW */
export const FONT_ICON_ROTATE_CW = '';

/** @const {string} FONT_ICON_SAVE */
export const FONT_ICON_SAVE = '';

/** @const {string} FONT_ICON_SCISSORS */
export const FONT_ICON_SCISSORS = '';

/** @const {string} FONT_ICON_SCREEN_SHARE */
export const FONT_ICON_SCREEN_SHARE = '';

/** @const {string} FONT_ICON_SCREEN */
export const FONT_ICON_SCREEN = '';

/** @const {string} FONT_ICON_SEARCH */
export const FONT_ICON_SEARCH = '';

/** @const {string} FONT_ICON_SERVER */
export const FONT_ICON_SERVER = '';

/** @const {string} FONT_ICON_SESSION */
export const FONT_ICON_SESSION = '';

/** @const {string} FONT_ICON_SETTINGS */
export const FONT_ICON_SETTINGS = '';

/** @const {string} FONT_ICON_SHARE_2 */
export const FONT_ICON_SHARE_2 = '';

/** @const {string} FONT_ICON_SHARE */
export const FONT_ICON_SHARE = '';

/** @const {string} FONT_ICON_SHIELD */
export const FONT_ICON_SHIELD = '';

/** @const {string} FONT_ICON_SHOP */
export const FONT_ICON_SHOP = '';

/** @const {string} FONT_ICON_SHUFFLE */
export const FONT_ICON_SHUFFLE = '';

/** @const {string} FONT_ICON_SIDEBAR */
export const FONT_ICON_SIDEBAR = '';

/** @const {string} FONT_ICON_SKIP_BACK */
export const FONT_ICON_SKIP_BACK = '';

/** @const {string} FONT_ICON_SKIP_FORWARD */
export const FONT_ICON_SKIP_FORWARD = '';

/** @const {string} FONT_ICON_SLACK */
export const FONT_ICON_SLACK = '';

/** @const {string} FONT_ICON_SLASH */
export const FONT_ICON_SLASH = '';

/** @const {string} FONT_ICON_SMARTPHONE */
export const FONT_ICON_SMARTPHONE = '';

/** @const {string} FONT_ICON_SPEAKER */
export const FONT_ICON_SPEAKER = '';

/** @const {string} FONT_ICON_SQUARE */
export const FONT_ICON_SQUARE = '';

/** @const {string} FONT_ICON_STAR_OUTLINED */
export const FONT_ICON_STAR_OUTLINED = '';

/** @const {string} FONT_ICON_STAR */
export const FONT_ICON_STAR = '';

/** @const {string} FONT_ICON_STOP_CIRCLE */
export const FONT_ICON_STOP_CIRCLE = '';

/** @const {string} FONT_ICON_STORE */
export const FONT_ICON_STORE = '';

/** @const {string} FONT_ICON_SUN */
export const FONT_ICON_SUN = '';

/** @const {string} FONT_ICON_SUNRISE */
export const FONT_ICON_SUNRISE = '';

/** @const {string} FONT_ICON_SUNSET */
export const FONT_ICON_SUNSET = '';

/** @const {string} FONT_ICON_TABLET */
export const FONT_ICON_TABLET = '';

/** @const {string} FONT_ICON_TAG */
export const FONT_ICON_TAG = '';

/** @const {string} FONT_ICON_TARGET */
export const FONT_ICON_TARGET = '';

/** @const {string} FONT_ICON_THERMOMETER */
export const FONT_ICON_THERMOMETER = '';

/** @const {string} FONT_ICON_THUMBS_DOWN */
export const FONT_ICON_THUMBS_DOWN = '';

/** @const {string} FONT_ICON_THUMBS_UP_FILL */
export const FONT_ICON_THUMBS_UP_FILL = '';

/** @const {string} FONT_ICON_THUMBS_UP */
export const FONT_ICON_THUMBS_UP = '';

/** @const {string} FONT_ICON_THUNDERBOLT_CABLE */
export const FONT_ICON_THUNDERBOLT_CABLE = '';

/** @const {string} FONT_ICON_TIME */
export const FONT_ICON_TIME = '';

/** @const {string} FONT_ICON_TOGGLE_LEFT */
export const FONT_ICON_TOGGLE_LEFT = '';

/** @const {string} FONT_ICON_TOGGLE_RIGHT */
export const FONT_ICON_TOGGLE_RIGHT = '';

/** @const {string} FONT_ICON_TOOLS */
export const FONT_ICON_TOOLS = '';

/** @const {string} FONT_ICON_TRASH_2 */
export const FONT_ICON_TRASH_2 = '';

/** @const {string} FONT_ICON_TRASH */
export const FONT_ICON_TRASH = '';

/** @const {string} FONT_ICON_TRENDING_DOWN */
export const FONT_ICON_TRENDING_DOWN = '';

/** @const {string} FONT_ICON_TRENDING_UP */
export const FONT_ICON_TRENDING_UP = '';

/** @const {string} FONT_ICON_TRIANGLE */
export const FONT_ICON_TRIANGLE = '';

/** @const {string} FONT_ICON_TWITTER */
export const FONT_ICON_TWITTER = '';

/** @const {string} FONT_ICON_TYPE */
export const FONT_ICON_TYPE = '';

/** @const {string} FONT_ICON_UMBRELLA */
export const FONT_ICON_UMBRELLA = '';

/** @const {string} FONT_ICON_UNLINK */
export const FONT_ICON_UNLINK = '';

/** @const {string} FONT_ICON_UNLOCK */
export const FONT_ICON_UNLOCK = '';

/** @const {string} FONT_ICON_UPLOAD_CLOUD */
export const FONT_ICON_UPLOAD_CLOUD = '';

/** @const {string} FONT_ICON_UPLOAD */
export const FONT_ICON_UPLOAD = '';

/** @const {string} FONT_ICON_USER_CHECK */
export const FONT_ICON_USER_CHECK = '';

/** @const {string} FONT_ICON_USER_MINUS */
export const FONT_ICON_USER_MINUS = '';

/** @const {string} FONT_ICON_USER_OUTLINE */
export const FONT_ICON_USER_OUTLINE = '';

/** @const {string} FONT_ICON_USER_PLUS */
export const FONT_ICON_USER_PLUS = '';

/** @const {string} FONT_ICON_USER_X */
export const FONT_ICON_USER_X = '';

/** @const {string} FONT_ICON_USER */
export const FONT_ICON_USER = '';

/** @const {string} FONT_ICON_USERS */
export const FONT_ICON_USERS = '';

/** @const {string} FONT_ICON_VIDEO_OFF */
export const FONT_ICON_VIDEO_OFF = '';

/** @const {string} FONT_ICON_VIDEO_PLAYER */
export const FONT_ICON_VIDEO_PLAYER = '';

/** @const {string} FONT_ICON_VIDEO */
export const FONT_ICON_VIDEO = '';

/** @const {string} FONT_ICON_VOICEMAIL */
export const FONT_ICON_VOICEMAIL = '';

/** @const {string} FONT_ICON_VOLUME_1 */
export const FONT_ICON_VOLUME_1 = '';

/** @const {string} FONT_ICON_VOLUME_2 */
export const FONT_ICON_VOLUME_2 = '';

/** @const {string} FONT_ICON_VOLUME_LOW */
export const FONT_ICON_VOLUME_LOW = '';

/** @const {string} FONT_ICON_VOLUME_MAX */
export const FONT_ICON_VOLUME_MAX = '';

/** @const {string} FONT_ICON_VOLUME_MID */
export const FONT_ICON_VOLUME_MID = '';

/** @const {string} FONT_ICON_VOLUME_OFF */
export const FONT_ICON_VOLUME_OFF = '';

/** @const {string} FONT_ICON_VOLUME_X */
export const FONT_ICON_VOLUME_X = '';

/** @const {string} FONT_ICON_VOLUME */
export const FONT_ICON_VOLUME = '';

/** @const {string} FONT_ICON_WARNING_FILL */
export const FONT_ICON_WARNING_FILL = '';

/** @const {string} FONT_ICON_WARNING_SQUARE_FILL */
export const FONT_ICON_WARNING_SQUARE_FILL = '';

/** @const {string} FONT_ICON_WATCH */
export const FONT_ICON_WATCH = '';

/** @const {string} FONT_ICON_WEBCAM_OFF */
export const FONT_ICON_WEBCAM_OFF = '';

/** @const {string} FONT_ICON_WEBCAM */
export const FONT_ICON_WEBCAM = '';

/** @const {string} FONT_ICON_WIFI */
export const FONT_ICON_WIFI = '';

/** @const {string} FONT_ICON_WIND */
export const FONT_ICON_WIND = '';

/** @const {string} FONT_ICON_X_CIRCLE */
export const FONT_ICON_X_CIRCLE = '';

/** @const {string} FONT_ICON_X_SQUARE */
export const FONT_ICON_X_SQUARE = '';

/** @const {string} FONT_ICON_X */
export const FONT_ICON_X = '';

/** @const {string} FONT_ICON_ZAP */
export const FONT_ICON_ZAP = '';

/** @const {string} FONT_ICON_ZOOM_IN */
export const FONT_ICON_ZOOM_IN = '';

/** @const {string} FONT_ICON_ZOOM_OUT */
export const FONT_ICON_ZOOM_OUT = '';
