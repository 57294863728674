{
    "email_templates": {
        "do_not_send": "Don't send this email"
    },
    "email_templates_editor": {
        "copy_template_to_clipboard": "Copy template",
        "label": "Email editor",
        "merge_tags": {
            "meeting": {
                "date": "Date",
                "description": "Description",
                "location": "Location",
                "organizer": "Organizer",
                "organizer_fname": "Organizer's first name",
                "organizer_lname": "Organizer's last name",
                "section_name": "Meeting details",
                "time": "Time",
                "title": "Title"
            },
            "session": {
                "lease_time": "Available claim time",
                "section_name": "Session details",
                "waitlisted_session": "Waitlisted session name"
            }
        },
        "messages": {
            "not_loaded": "Could not load the template editor. Please try again later.",
            "not_uploaded": "Could not upload the asset. Please try again later."
        },
        "metadata_error": "Please try again later. If the problem persists contact the support.",
        "mode_switch_btn_label_off": "Use my own HTML code",
        "mode_switch_btn_label_on": "Use design editor",
        "paste_template_from_clipboard": "Paste template",
        "save_error": "Email template could not be saved.",
        "save_success": "Email template has been saved.",
        "subject": "The email subject should be no more than 60 characters or 10 words, in order to display properly on mobile and on all email providers.",
        "template_error": "Could not load the template at this moment. Try again later. If the problem persists please contact the support."
    },
    "emailing": {
        "actions": {
            "send_email": "Send email",
            "send_email_placeholder": "Send an email"
        },
        "list": {
            "headers": {
                "clicks": "Clicks",
                "open": "Opens",
                "recipients": "Recipients",
                "sent_at": "Sent",
                "status": "Status",
                "template": "Template",
                "to": "To"
            }
        },
        "logs": {
            "analytics": "Analytics",
            "headers": {
                "clicked": "Clicked:",
                "clicks": "Clicks",
                "last_clicked": "Last clicked:",
                "last_opened": "Last opened:",
                "name": "Name",
                "opened": "Opened:",
                "opens": "Opens",
                "queued": "Queued",
                "send_timestamp": "Attempt",
                "status": "Status",
                "subject": "Subject",
                "to": "Email"
            },
            "log_html": "Email preview",
            "messages": {
                "occurrences": "Never|Once|{0} times"
            },
            "open_in_editor": "Open in editor",
            "overview": "Overview",
            "preview": "Show email",
            "reports": "Reports",
            "smtp_events": "SMTP events"
        },
        "mailing": {
            "details": {
                "headers": {
                    "progress": "Progress",
                    "scheduled_at": "Scheduled at",
                    "status": "Status",
                    "template": "Template"
                },
                "no_address": "Skipped due to empty email address",
                "no_doc": "Skipped due to missing document record",
                "processed": "Processed (both skipped and successfully delivered)",
                "title": "Recipients",
                "unsubscribed": "Skipped due to being unsubscribed"
            }
        },
        "messages": {
            "load_failed": "Could not load the list of scheduled emails. Please try again later.",
            "prompt_delete": "Are you sure you want to cancel this mailing?",
            "recipients_info": "Number of users currently matching the targeting rule. Final recipients will be determined before sending."
        },
        "statuses": {
            "cancelled": "Cancelled",
            "failed": "Failed",
            "scheduled": "Scheduled",
            "sending": "Sending",
            "sent": "Sent"
        },
        "targeting": {
            "intro": "Choose who is going to receive the email.",
            "label": "Custom targeting",
            "text_for_hidden_to": "This will not be sent to attendees who match the below rule(s).",
            "text_for_visible_to": "This will be sent to attendees who match the below rule(s).",
            "will_send_to": "Send to everyone"
        },
        "targets": {
            "active": "Active users",
            "all": "All users",
            "cancelled": "Cancelled",
            "everyone": "Everyone",
            "inactive": "Inactive users",
            "not-invited": "Not invited",
            "pending": "Invited, not activated users",
            "rsvp-not-invited": "RSVP - not invited",
            "selected": "Selected users",
            "targeted": "Targeted users"
        }
    }
}
